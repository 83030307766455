import React from 'react'
import { Button } from 'components/shared/Button'
import Tooltip from '@material-ui/core/Tooltip'

function WebexButton(data: WebexProps) {
  const [chatStarted, setChatStarted] = React.useState(false)
  const [buttonText, setButtonText] = React.useState(data.text)
  const webexteams = process.env.REACT_APP_VOC_WEBEX_ROOM || `webexteams://im?space=9dba1850-45a5-11e9-a82a-335839554d4c`;
  const handleWebexSessionClick = () => {
    window.location.href = webexteams;
    setButtonText('Webex session started');
  }
  return (
    <Tooltip title={data.tooltip} placement="top" aria-label="Webex support">
      <>
        <Button
          variant="primary"
          disabled={chatStarted}
          onClick={() => {
            setChatStarted(true)
            handleWebexSessionClick()
          }}
          data-pho="webexButton"
        >
          {buttonText}
        </Button>
      </>
    </Tooltip>
  )
}

interface WebexProps {
  type: string
  text: string
  tooltip: string
  markdownMessage?: string
  developer?: Array<string>
  roomName: string
}

export default WebexButton
