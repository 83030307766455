import first from 'lodash/first'
import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { VOCUserPermission } from 'components/voiceOfCustomer/types'
import { getVocClient } from 'components/voiceOfCustomer/utils'
import { queries } from 'components/voiceOfCustomer'
import useVisibilitySensor from 'hooks/useVisibilitySensor'
import useGroups from 'hooks/useGroups'
import useApps from 'hooks/useApps'
import useAuth from 'hooks/useAuth'
import useFilterValues from 'hooks/useFilterValues'

const POLL_INTERVAL_THIRTY_MINUTES = 1800000

export default function useVocAuth() {
  const auth = useAuth()
  const user = auth?.user
  const { currentGroup } = useGroups()
  const { entityID, app } = useApps()
  const isVisible = useVisibilitySensor()
  const { environment } = useFilterValues()
  const client = getVocClient(environment || 'production')
  const [hasVocAccess] = useState<boolean>(
    user?.roles?.some((role: string) => role.includes('prism-full') || role.includes('voc-read')) ||
      false
  )
  const [hasFeedbackAccess] = useState<boolean>(
    user?.roles?.includes('customer-feedback-access') ?? false
  )

  const entityName = currentGroup ? currentGroup.description : `${app.name}@${app.type}`
  const variables = {
    entityID,
    authID: user?.id,
    email: user?.email,
  }

  const getUserPermissionOneQuery = useQuery(queries.GET_USER_PERMISSION_ONE, {
    client,
    variables,
  })
  const { loading, error, data } = getUserPermissionOneQuery
  const permission = first(data?.getUserPermissionOne || []) as VOCUserPermission

  useEffect(() => {
    getUserPermissionOneQuery.refetch()
  }, [entityID]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isVisible) {
      getUserPermissionOneQuery.startPolling(POLL_INTERVAL_THIRTY_MINUTES)
    } else {
      getUserPermissionOneQuery.stopPolling()
    }
    return () => getUserPermissionOneQuery.stopPolling()
  }, [isVisible, getUserPermissionOneQuery])

  return {
    permissionId: permission?._id,
    hasVocAccess,
    hasFeedbackAccess,
    isAdmin: permission?.user?.role === 'admin',
    canRead: permission?.read === true,
    canWrite: permission?.write === true || permission?.user?.role === 'admin',
    vocAuthError: error,
    vocAuthLoading: loading,
    entityID,
    entityName,
    columnGroupID: permission?.columnGroupID,
  }
}
