import { useState, useEffect } from 'react'

const useVisibilitySensor = (): boolean => {
  const [isVisible, setIsVisible] = useState(!document.hidden)

  function onTabChange() {
    setIsVisible(!document.hidden)
  }

  function onWindowChange(isFocused: boolean) {
    setIsVisible(isFocused)
  }

  useEffect(() => {
    window.addEventListener('focus', () => onWindowChange(true))
    window.addEventListener('blur', () => onWindowChange(false))
    document.addEventListener('visibilitychange', onTabChange)
    document.addEventListener('pagehide', onTabChange) // Safari

    return () => {
      window.removeEventListener('focus', () => onWindowChange(true))
      window.removeEventListener('blur', () => onWindowChange(false))
      document.removeEventListener('visibilitychange', onTabChange)
      document.removeEventListener('pagehide', onTabChange) // Safari
    }
  }, [])

  return isVisible
}

export default useVisibilitySensor
